<template>
  <div>
    <a-modal
      v-model="modals"
      @ok="handleOk"
      :footer="null"
      width="740px"
      :centered="true"
    >
      <div slot="title">Plan {{ plan }}</div>

      <div class="plan-content">
        <div class="tableplan">
          <table style="">
            <thead>
              <tr>
                <td>Manfaat</td>
                <td>Benefit</td>
                <td>Plan {{ databenefit[0].val }}</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in databenefit.filter((key) => key.rowid > 1)"
                :key="i"
              >
                <td>{{ item.manfaat_plan }}</td>
                <td>{{ item.maksimal_limit }}</td>
                <td>{{ item.val }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormAction" });
  },
  data() {
    return {
      modals: false,
      plan: "",
      isLoading: false,
      data: [],
      loadingbenefit: false,
      databenefit: [],
    };
  },
  mounted() {
    document.body.classList.add("ModalCustom");
  },
  destroyed() {
    document.body.classList.remove("ModalCustom");
  },
  methods: {
    moment,

    handleOk() {
      this.modals = false;
    },
    handleCancel() {
      this.modals = true;
    },

    Show(id, plan, value) {
      this.modals = true;
      this.plan = value;
      this.loadingbenefit = true;
      //let val = value.replace(/\s/g, "");

      this.$store
        .dispatch("getBenefit", {
          id: id,
          plan: plan,
          value: value,
        })
        .then((response) => {
          this.loadingbenefit = false;
          this.databenefit = response.data.data;
        });
    },
  },
};
</script>

<style>
.ModalCustom .ant-modal-content {
  padding: 2px 5px !important;
}

.ModalCustom .ant-modal-header {
  border-radius: 24px !important;
}

.ModalCustom .ant-modal-close-x {
  margin-right: 20px;
}
</style>
