<template>
  <div>
    <div class="allcard" style="height: calc(100vh - (151px))">
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- Start Header -->
        <div class="ContentHeader">
          <div class="HeaderAll">
            <!-- Slot Breadcrumb -->
            <slot name="onlybreadcrumb"></slot>
            <a-breadcrumb>
              <a-breadcrumb-item
                ><router-link :to="{ name: 'Home' }">Home</router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
                <router-link
                  :to="{
                    name: item.nameroute,
                    params: item.param,
                    query: item.query,
                  }"
                  v-if="i + 1 < breadcrumb.length"
                  >{{ item.name }}</router-link
                >
                <span v-else>{{ item.name }}</span>
              </a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <!-- Slot Benefit Header-->
        </div>
        <!-- End Header -->
        <div class="formpeserta">
          <!-- Start Content Here -->
          <a-form :form="form" @submit="Submit">
            <a-row :gutter="[15, 15]">
              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Informasi Peserta</h1>

                  <a-form-item label="Nama Peserta">
                    <!-- <div>{{ data.nama_peserta }}</div> -->
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nama_peserta',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      @change="namaPeserta"
                    />
                  </a-form-item>

                  <a-form-item label="Tgl Join">
                    <div>
                      <a-icon type="calendar" v-if="data.tgl_join != null" />
                      {{ data.tgl_join == null ? "-" : data.tgl_join }}
                    </div>
                  </a-form-item>

                  <a-form-item label="Nama Karyawan">
                    <!-- <div>{{ data.nama_karyawan }}</div> -->
                    <div>{{ namaKaryawans }}</div>
                  </a-form-item>

                  <a-form-item label="Tanggal Lahir">
                    <a-date-picker
                      style="width: 100%"
                      autocomplete="off"
                      v-decorator="[
                        'tgl_lahir',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      format="DD MMM YYYY"
                    />
                  </a-form-item>

                  <a-form-item label="NIK">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nik',
                        // {
                        //   rules: [
                        //     {
                        //       required: true,
                        //       message: 'Field ini harus diisi',
                        //     },
                        //   ],
                        // },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>

                  <a-row :gutter="[15, 15]">
                    <a-col :lg="12">
                      <a-form-item label="Jenis Kelamin">
                        <a-select
                          v-decorator="[
                            'jenis_kelamin',
                            {
                              rules: [
                                {
                                  required: true,
                                },
                              ],
                            },
                          ]"
                          @change="jenisKelamin"
                          placeholder="Jenis Kelamin"
                        >
                          <a-select-option value="M"
                            ><a-icon type="man" /> Pria</a-select-option
                          >
                          <a-select-option value="F"
                            ><a-icon
                              type="woman"
                              :style="{ color: '#DF5684' }"
                            />
                            Wanita</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :lg="12">
                      <a-form-item label="Status">
                        <a-select
                          v-decorator="[
                            'status',
                            {
                              rules: [
                                {
                                  required: true,
                                },
                              ],
                            },
                          ]"
                          @change="StatusKaryawan"
                          placeholder="Status"
                        >
                          <a-select-option value="E"> Employee</a-select-option>
                          <a-select-option value="S"> Spouse</a-select-option>

                          <a-select-option value="C"> Child</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>

                  <div v-if="changeplan">
                    <a-form-item label="Kategori Usia">
                      <a-select
                        v-decorator="[
                          'kategori_usia',
                          {
                            rules: [
                              {
                                required: true,
                              },
                            ],
                          },
                        ]"
                        placeholder="Kategori Usia"
                        @change="kategoriUsia"
                      >
                        <a-select-option
                          v-for="(item, i) in usia"
                          :value="item.id"
                          :key="i"
                        >
                          {{ item.title }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>

                    <a-form-item label="Plan">
                      <a-select
                        v-decorator="[
                          'plan',
                          {
                            rules: [
                              {
                                required: true,
                              },
                            ],
                          },
                        ]"
                        placeholder="Plan"
                      >
                        <a-select-option
                          v-for="(item, i) in plan"
                          :value="item.id"
                          :key="i"
                        >
                          {{
                            item.benefit.substring(0, item.benefit.length - 2)
                          }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>

                    <a-button type="link-custom" @click="cancelEditPlan">
                      <a-icon type="undo" /> Undo Plan
                    </a-button>
                  </div>

                  <div v-else>
                    <div class="_thr profileplan">
                      <label>Plan</label>
                      <div>
                        <div>
                          <ul>
                            <li
                              v-for="(item, i) in existingplan.filter(
                                (key) => key.plan != null
                              )"
                              :key="i"
                              @click="
                                planshow(
                                  $route.params.id,
                                  item.plan.split(' ')[0],
                                  item.plan.split(' ')[2]
                                )
                              "
                            >
                              <img
                                :src="
                                  require(`@/assets/img/icon/${item.plan
                                    .split(' ')[0]
                                    .toLowerCase()}.svg`)
                                "
                              />

                              {{ item.plan }}
                            </li>
                          </ul>
                        </div>
                        <div style="margin-left: 10px; margin-top: -10px">
                          <a-button
                            type="primary"
                            style="
                              padding: 9px !important;
                              height: 41px !important;
                            "
                            @click="editbenefit"
                          >
                            <a-icon type="edit" /> Edit Plan</a-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>

              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Bank Account</h1>

                  <a-form-item label="Nama Bank">
                    <a-input
                      autocomplete="off"
                      v-decorator="['nama_bank']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="No.Rekening">
                    <a-input
                      autocomplete="off"
                      v-decorator="['no_rekening']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Pemilik Rekening">
                    <a-input
                      autocomplete="off"
                      v-decorator="['nama_rekening']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="No.Telp">
                    <a-input
                      autocomplete="off"
                      v-decorator="['no_telp']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Alamat Email">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              type: 'email',
                              message: 'Format email masih salah',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </div>
              </a-col>
              <a-col :lg="8">
                <div
                  class="cardForm"
                  ref="heightCardForm"
                  :style="`min-height:${heightCardForm}px`"
                >
                  <h1>Company Information</h1>
                  <a-form-item label="Nama Group">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'group',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      :disabled="true"
                    />
                  </a-form-item>
                  <a-form-item label="Nama Perusahaan">
                    <a-select
                      autocomplete="off"
                      v-decorator="[
                        'nama_perusahaan',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]"
                      placeholder="Input disini"
                      :disabled="true"
                    >
                      <a-select-option
                        v-for="(item, i) in perusahaan"
                        :key="i"
                        :value="item.id"
                        >{{ item.nama_perusahaan }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                  <a-form-item label="Cabang">
                    <a-input
                      autocomplete="off"
                      v-decorator="['cabang']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Sub Cabang I">
                    <a-input
                      autocomplete="off"
                      v-decorator="['sub_cabang_1']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                  <a-form-item label="Sub Cabang II">
                    <a-input
                      autocomplete="off"
                      v-decorator="['sub_cabang_2']"
                      placeholder="Input disini"
                    />
                  </a-form-item>

                  <a-form-item label="Keterangan">
                    <a-textarea
                      autocomplete="off"
                      v-decorator="['keterangan']"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </div>
              </a-col>
              <a-col :lg="24">
                <div class="submitcard" style="border: 1px solid #d8ddec">
                  <a-button type="default" @click="batalkan">Batalkan</a-button>
                  <a-button type="primary" html-type="submit">Submit</a-button>
                </div>
              </a-col>
            </a-row>
          </a-form>
          <!-- End Content Here -->
        </div>
      </simplebar>
    </div>
    <Confirm ref="Confirm" />
    <BenefitPlan ref="plan" />
  </div>
</template>
<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import Confirm from "@/components/Modal/confirmDataPeserta.vue";
import BenefitPlan from "@/components/Modal/benefitPlan.vue";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "Form Data Peserta" });
  },

  components: {
    Confirm,
    BenefitPlan,
    simplebar,
  },
  data() {
    return {
      heightCardForm: 0,
      data: {},
      usia: [],
      plan: [],
      existingplan: [],
      changeplan: false,
      perusahaan: [],
      databenefit: [],
      loadingbenefit: false,
      namaKaryawans: "",
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Data Peserta",
          nameroute: "ListDataPeserta",
          param: { id: this.$route.params.id },
        },
        {
          name: "Edit Peserta",
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);

      if (from.name != null) {
        vm.breadcrumb = [
          {
            name: "Daftar Group",
            nameroute: "ListDaftarGroup",
            param: {},
          },
          {
            name: from.meta.title,
            nameroute: from.name,
            param: { id: from.params.id },
            query: from.query,
          },
          {
            name: "Edit Peserta",
            nameroute: "",
            param: {},
          },
        ];
      } else {
        vm.breadcrumb = [
          {
            name: "Daftar Group",
            nameroute: "ListDaftarGroup",
            param: {},
          },
          {
            name: "Data Peserta",
            nameroute: "ListDataPeserta",
            param: { id: vm.$route.params.id },
          },
          {
            name: "Edit Peserta",
            nameroute: "",
            param: {},
          },
        ];
      }
    });
  },
  mounted() {
    this.matcheight();
    this.getPerusahaan();
    this.fetch();
  },
  watch: {
    changeplan: {
      handler: function (action) {
        if (action == true) {
          if (this.form.getFieldValue("status") != undefined) {
            //console.log(this.form.getFieldValue("status"));
            let status = this.form.getFieldValue("status");
            let gender = this.form.getFieldValue("jenis_kelamin");

            this.getTitleBenefit(gender, status);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getPerusahaan() {
      this.$store
        .dispatch("GetDaftarPerusahaan", {
          page: 1,
          pageSizes: 15,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.perusahaan = response.data.data;
        });
    },
    cancelEditPlan() {
      this.changeplan = false;
    },
    editbenefit() {
      this.changeplan = true;
    },
    kategoriUsia(e) {
      //alert(e);
      this.form.resetFields("plan");
      this.$store
        .dispatch("getKategoriUsia", {
          id: e,
        })
        .then((response) => {
          this.plan = response.data.data;
        });
    },
    namaPeserta(e) {
      if (this.form.getFieldValue("status") == "E") {
        this.namaKaryawans = e.target.value;
      } else {
        this.namaKaryawans = this.data.nama_karyawan;
      }
      // alert(this.form.getFieldValue("status"));
    },
    jenisKelamin(e) {
      this.form.resetFields("kategori_usia");
      this.form.resetFields("plan");
      if (this.form.getFieldValue("status") != null) {
        let status = this.form.getFieldValue("status");
        this.getTitleBenefit(e, status);
        //console.log(this.form.getFieldValue("jenis_kelamin"));
      } else {
        this.$message.error("Status karyawan belum dipilih");
      }
    },

    StatusKaryawan(e) {
      this.form.resetFields("kategori_usia");
      this.form.resetFields("plan");
      if (this.form.getFieldValue("jenis_kelamin") != null) {
        let gender = this.form.getFieldValue("jenis_kelamin");
        this.getTitleBenefit(gender, e);
        // console.log("jenis kelamin", gender);
        // console.log("jenis kelamin", e);
      } else {
        this.$message.error("Jenis kelamin belum dipilih");
      }

      if (e == "E") {
        this.namaKaryawans = this.form.getFieldValue("nama_peserta");
      } else {
        this.namaKaryawans = this.data.nama_karyawan;
      }
    },

    getTitleBenefit(gender, status) {
      //alert("keluar");
      this.$store
        .dispatch("getTitleBenefit", {
          jenis_kelamin: gender,
          status: status,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.usia = response.data.data;

          console.log(this.usia);
        });
    },
    fetch() {
      this.$store
        .dispatch("GetDetailPeserta", {
          id: this.$route.params.idpeserta,
        })
        .then((response) => {
          this.data = response.data.data;
          console.log("data", this.data);
          this.form.setFieldsValue({
            nama_peserta: response.data.data.nama_peserta,
            nik: response.data.data.nik,
            tgl_lahir: response.data.data.tgl_lahir,
            jenis_kelamin:
              response.data.data.jenis_kelamin == "Pria" ? "M" : "F",
            status:
              response.data.data.status == "Employee"
                ? "E"
                : response.data.data.status == "Spouse"
                ? "S"
                : "C",
            nama_bank: response.data.data.nama_bank,
            no_rekening: response.data.data.no_rekening,
            nama_rekening: response.data.data.rekening_atas_nama,
            no_telp: response.data.data.no_telp,
            email: response.data.data.alamat_email,
            group: response.data.data.nama_group,
            nama_perusahaan: response.data.data.id_job_order_perusahaan,
            cabang: response.data.data.cabang,
            sub_cabang_1: response.data.data.sub_cabang_1,
            sub_cabang_2: response.data.data.sub_cabang_2,
            keterangan: response.data.data.ket,
          });

          this.namaKaryawans = response.data.data.nama_karyawan;

          this.existingplan = [
            {
              plan:
                response.data.data.ri != null
                  ? "RI - " + response.data.data.ri
                  : null,
            },
            {
              plan:
                response.data.data.rj != null
                  ? "RJ - " + response.data.data.rj
                  : null,
            },

            {
              plan:
                response.data.data.kc != null
                  ? "KC - " + response.data.data.kc
                  : null,
            },

            {
              plan:
                response.data.data.rg != null
                  ? "RG - " + response.data.data.rg
                  : null,
            },

            {
              plan:
                response.data.data.mt != null
                  ? "RB - " + response.data.data.mt
                  : null,
            },
          ];
        });
    },
    matcheight() {
      this.heightCardForm = this.$refs.heightCardForm.clientHeight;
    },

    planshow(id, plan, value) {
      this.$refs.plan.Show(id, plan, value);
    },
    batalkan() {
      this.$router.push({ name: "ListDataPeserta" });
    },
    Submit(e) {
      e.preventDefault();
      // this.$refs.Confirm.Show();

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          // console.log(this.existingplan.filter((key) => key.plan != null));

          let plans =
            values.plan == undefined
              ? this.existingplan.filter((key) => key.plan != null)
              : this.plan.filter((key) => key.id == values.plan);
          let a = {
            nama_peserta: values.nama_peserta,
            nik: this.data.nik,
            nama_karyawan:
              this.data.nama_karyawan == undefined
                ? null
                : this.data.nama_karyawan,
            othersplan: plans,
            name_perusahaan_real: this.data.nama_perusahaan,
            id_karyawan: this.data.id_karyawan,
          };
          this.$refs.Confirm.Show({ ...a, ...values }, "Edit");
        }
      });
    },
  },
};
</script>
